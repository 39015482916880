
























































import Vue from "vue";
import "../style/about.sass";
import Component from "vue-class-component";

@Component
export default class About extends Vue {

}
