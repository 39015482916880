




























import Vue from "vue";
import "../style/bigSealPage.sass";
import Component from "vue-class-component";

@Component
export default class Contact extends Vue {

}
