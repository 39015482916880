







import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import "../style/datasets.sass";
import Api from "../util/api";

export interface DatasetData {
  id: string;
  title: string;
  description: string;
  license: string;
}

@Component
export default class Dataset extends Vue {
  @Prop() dataset: DatasetData;
  private img: any = "";

  public async mounted() {
    const image = await Api.get(`datasets/${this.dataset.id}/thumb`);
    this.img = Api.staticUrl(image.file);
  }
}
