

































































import Component from 'vue-class-component';
import Vue from 'vue';
import "../style/home.sass";
import ActionButton from '../components/ActionButton.vue';
import Accordion from '../components/Accordion.vue';

@Component({components: {ActionButton, Accordion}})
export default class Home extends Vue { 

  private goToBrowse() {
   this.$router.push('/browse'); 
  }

  private goToRemix() {
   this.$router.push('/remix'); 
  }

  private goToCreate() {
   this.$router.push('/create'); 
  }

}

