




























import "../style/browse.sass";
import "../style/page.sass";
import Component from 'vue-class-component';
import Vue from 'vue';
import Dataset, {DatasetData} from "../components/Dataset.vue";
import Location from "../components/Location.vue";
import Loadable from "../components/Loadable.vue";
import Api from "../util/api";

@Component({components: {Loadable, Dataset, Location}})
export default class Datasets extends Vue {
  private datasets: DatasetData[] = [];
  private loaded = false;

  public async mounted() {
    const datasetMap = await Api.get("datasets");
    this.datasets = Object.values(datasetMap);
    this.loaded = true;
  }
}
