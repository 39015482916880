








































import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from 'vue-property-decorator';
import "../style/fileUpload.sass";
import {CreateSettings, startCreateSession, startProcessing, uploadCreatedImage} from "../util/createAPI";

const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;
@Component
export default class FileUpload extends Vue {
  @Prop({default: false})
  public stickyTooltip: boolean;

  @Prop({default: ""})
  public tooltip: string;

  @Prop({default: STATUS_INITIAL})
  public currentStatus: number;

  @Prop({default: 'photo'})
  public uploadFieldName: string;

  @Prop({default: []})
  public uploadedFiles: any;

  @Prop({default: undefined})
  public uploadError: any;
  
  public previewImageUrl: string;

  public reset() {
    this.currentStatus = STATUS_INITIAL;
    this.uploadedFiles = [];
    this.uploadError = null;
    this.uploadFieldName = "photo";
  }

  private async save(formData: FormData) {
      // upload data to the server
      this.currentStatus = STATUS_SAVING;

      console.log("uploading...");
      //getCreateHeightmap(1);
      uploadCreatedImage(formData)
          .then((response: any) => {
              this.uploadedFiles = [].concat(response);
              this.currentStatus = STATUS_SUCCESS;
              this.$emit("successfulUpload");
          })
          .catch(err => {
              this.uploadError = err.response;
              this.currentStatus = STATUS_FAILED;
          });
  }

  private filesChange(fieldName: string, fileList: FileList) {
    // handle file changes
    const formData = new FormData();

    if (!fileList.length) return;

    this.uploadedFiles = fileList;
    this.previewImageUrl = URL.createObjectURL(fileList[0]);

    // append the files to FormData
    Array
        .from(Array(fileList.length).keys())
        .map(x => {
          formData.append(fieldName, fileList[x], fileList[x].name);
        });

    // save it
    this.save(formData);
  }

  public async processResults(shouldBeInversed: boolean, shouldBeLowQuality: boolean) {
      const settings: CreateSettings = {
          startX: 0,
          endX: 1000000,
          startY: 0,
          endY: 1000000,
          isInversed: shouldBeInversed,
          isLowQuality: shouldBeLowQuality};
      return await startProcessing(settings)
  }

  public isInitial() {
    return this.currentStatus === STATUS_INITIAL;
  }

  public isSaving() {
    return this.currentStatus === STATUS_SAVING;
  }

  public isSuccess() {
    return this.currentStatus === STATUS_SUCCESS;
  }

  public isFailed() {
    return this.currentStatus === STATUS_FAILED;
  }

}
