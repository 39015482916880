




























import "../style/accordion.sass";
import Component from 'vue-class-component';
import Vue from 'vue';
import {Prop} from 'vue-property-decorator';

@Component
export default class Accordion extends Vue {

  active = false;

  toggleActive(){
    this.active = !this.active
  }

  @Prop({default: ""})
  public title: string;

}
