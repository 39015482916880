



































import "../style/page.sass";
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class Page extends Vue {}
