




































import Component from "vue-class-component";
import Vue from "vue";
import ElementPalette from "../components/merge/ElementPalette.vue";
import DrawableSeal from "../components/merge/DrawableSeal.vue";
import {SealElement} from "../data/SealElement";
import "../style/remix.sass";
import "../style/bigSealPage.sass";
import ActionButton from '../components/ActionButton.vue';
import {ResultData} from "../data/ResultData";
import Api from "../util/api";

@Component({components: {ActionButton, ElementPalette, DrawableSeal}})
export default class Remix extends Vue {

  $refs!: {
    drawableSeal: DrawableSeal
  }

  private placeElement(element: SealElement) {
    this.$refs.drawableSeal.placeElement(element);
  }

  public mounted() {
    this.restore();
  }

  private restore() {
    if (ResultData.instance.backLink === "remix") {
      this.$refs.drawableSeal.deserialize(ResultData.instance.original);
    }
    ResultData.instance.backLink = "";
  }

  private async doExport() {
    const json = this.$refs.drawableSeal.getSerializedElements();
    ResultData.instance.backLink = "remix";
    ResultData.instance.original = this.$refs.drawableSeal.serialize();
    ResultData.instance.heightmap = await Api.getFile("merge", {data: json});
    await this.$router.push("result");
  }
}
