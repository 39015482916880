










































import Vue from "vue";
import Component from "vue-class-component";
import FileUpload from "../components/FileUpload.vue";
import "../style/bigSealPage.sass";
import ActionButton from "../components/ActionButton.vue";
import CheckBox from '../components/CheckBox.vue';
import {getCreateSessionHeightmap, getCreateSessionModel} from "../util/createAPI";
import {ResultData} from "../data/ResultData";

@Component({components: {FileUpload, ActionButton, CheckBox}})
export default class Create extends Vue {
  private showOptions = false;
  private isInversed = false;
  private isLowQuality = false;

    $refs!: {
        fileUpload: FileUpload
    };

  private upload() {
      this.$refs.fileUpload.reset();
      this.$nextTick(() => document.getElementById("createFileInput")?.click());
  }

  private async exportToResult() {
      await this.$refs.fileUpload.processResults(this.isInversed, this.isLowQuality);

      ResultData.instance.backLink = "/create";
      ResultData.instance.heightmap = await getCreateSessionHeightmap();
      ResultData.instance.original = null;

      await this.$router.push("result");
  }

}

