






















import Vue from "vue";
import Component from "vue-class-component";
import Renderer from '../graphics/renderer';
import {Prop, Watch} from 'vue-property-decorator';
import {Texture, TextureLoader} from "three";
import "../style/siegel3dcanvas.sass";

@Component
export default class Item3d extends Vue {

  @Prop({default: ""})
  public heightmap: string;

  @Prop({default: false})
  public stickyTooltip: boolean;

  @Prop({default: ""})
  public tooltip: string;

  $refs!: {
    canvas: HTMLDivElement
  }

  private renderer: Renderer;

  public mounted() {
    if (this.heightmap !== "") {
      this.onLoaded();
    }
  }

  @Watch("heightmap")
  public onLoaded() {
    const tex = new TextureLoader().load(this.heightmap, () => {
      this.renderer.plainRender();
    }, () => {}, (e) => {
      console.log(e)
    });
    this.renderer = new Renderer(this.$refs.canvas, tex);
  }
}
