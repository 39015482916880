







import "../style/loadable.sass";
import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

@Component
export default class Loadable extends Vue {
  @Prop({default: "Am laden"}) title: string;
  @Prop() loaded: boolean;
}
