

























import "../style/checkbox.sass";
import Component from 'vue-class-component';
import Vue from 'vue';
import {Model, Prop} from 'vue-property-decorator';

@Component
export default class CheckBox extends Vue {
  @Prop({default: false}) left: boolean;
  @Prop() title: string;
  @Model("change", {type: Boolean}) readonly checked: boolean;
}
