














































import Component from "vue-class-component";
import Vue from "vue";
import Location from '../components/Location.vue';
import "../style/details.sass";
import "../style/page.sass";
import {DatasetData} from "../components/Dataset.vue";
import Item3d from "../components/Item3d.vue";
import CheckBox from "../components/CheckBox.vue";
import ActionButton from "../components/ActionButton.vue";
import Api from "../util/api";

export interface Item {
  id: number;
  name: string;
  subjects: string[];
}

@Component({components: {ActionButton, CheckBox, Item3d, Location}})
export default class Details extends Vue {

  private dataset: DatasetData = {id: "", title: "", description: "", license: ""};
  private item: Item = {id: 0, subjects: [], name: ""};
  private heightmap: string = "";
  private original: string = "";
  private loaded = false;

  public async mounted() {
    const data = await Api.get(`datasets/${this.datasetId}/items/${this.itemId}`);
    this.item = data.item;
    this.dataset = data.dataset;
    const heightmapFile = await Api.get(this.itemFileUrl("heightmap"));
    this.heightmap = Api.staticUrl(heightmapFile.file);
    const originalFile = await Api.get(this.itemFileUrl("original"));
    this.original = Api.staticUrl(originalFile.file);
    this.loaded = true;
  }

  private get itemUrl(): string {
    return `datasets/${this.datasetId}/items/${this.itemId}`;
  }

  private itemFileUrl(file: "original" | "heightmap" | "stl"): string {
    return `${this.itemUrl}/${file}`;
  }

  private get datasetId(): string {
    return this.$route.params.dataset;
  }

  private get itemId(): string {
    return this.$route.params.item;
  }

  private shorterTag(tag: string): string {
    const maxLength = 15;
    if (tag.length > maxLength) {
      return tag.substring(0, maxLength - 3) + "...";
    }
    return tag;
  }

  private async downloadStl() {
    const stl = await Api.get(this.itemFileUrl("stl"));
    window.location = Api.staticUrl(stl.file) as any;
  }
}
