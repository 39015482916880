


























































import "../style/guide.sass";
import Component from 'vue-class-component';
import Vue from 'vue';

@Component
export default class Guide extends Vue {}
