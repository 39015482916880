







































import "../style/browse.sass";
import "../style/page.sass";
import Component from 'vue-class-component';
import Vue from 'vue';
import Location from '../components/Location.vue';
import {DatasetData} from "../components/Dataset.vue";
import PageOffset from "../components/PageOffset.vue";
import {Watch} from "vue-property-decorator";
import Dropdown from "../components/Dropdown.vue";
import Api from "../util/api";
import Loadable from "../components/Loadable.vue";

interface Item {
  id: string;
  name: string;
  subjects: string[];
}

interface DatasetInfo {
  dataset: DatasetData;
  itemsCount: number;
}

@Component({components: {Dropdown, PageOffset, Location, Loadable}})
export default class Browse extends Vue {
  private datasetName: string = "";
  private itemsCount = 1;
  private loadedItems: Item[] = [];
  private hasLoadedItems = false;
  private loadedThumbs: string[] = [];
  private limit = 25;
  private offset = 0;

  private async mounted() {
    const info: DatasetInfo = await Api.get(`datasets/${this.datasetId}/items/info`);
    this.datasetName = info.dataset.title;
    this.itemsCount = info.itemsCount;
    await this.loadItems();
    await this.loadThumbs();
  }

  @Watch("offset")
  @Watch("limit")
  private async reloadPage() {
    await this.loadItems();
    await this.loadThumbs();
  }

  private async loadItems() {
    this.loadedItems = await Api.get(`datasets/${this.datasetId}/items`, {params: {limit: this.limit, offset: this.offset}});
    this.hasLoadedItems = true;
  }

  private async loadThumbs() {
    for (const item of this.loadedItems) {
      const thumb = await Api.get(`datasets/${this.datasetId}/items/${item.id}/original`);
      this.loadedThumbs.push(Api.staticUrl(thumb.file));
    }
  }

  private get datasetId(): string {
    return this.$route.params.dataset;
  }
}
